export const MARKET_ITEM_TYPES = {
  DESSERT: 'dessert',
  BREAKFAST: 'breakfast',
  SIDE: 'side',
  LUNCH: 'lunch',
  KIDS_FAVOURITES: 'kids favourites',
  KIDS_LUNCHBOX: 'kids lunchbox',
  SNACKS_DRINKS: 'snacks & drinks',
  BUTCHERS_COUNTER: 'the butchers counter',
  PROTEINS: 'proteins',
  READY_TO_COOK: 'ready to cook',
  ENTERTAINING: 'entertaining',
  SEASONAL: 'seasonal',
  PIZZAS: 'pizzas',
  PASTAS: 'pastas',
  KITCHEN_BASICS: 'kitchen basics',
  HEALTHY_OPTIONS: 'healthy options',
  GIFTS: 'gifts',
  CELEBRATIONS: 'celebrations',
  OTHERS: 'others',
};
